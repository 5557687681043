export default function GetData() {
        return {
        "apitype":"pointsvisited",
        "id":"points_users_id",
        "subidname":"point_name",
        "options":[],
        "nameSingle":"points_user",
        "nameMultiple":"Visited points",
        "export": [
            {
                name:"Section ID",
                field:"section_id",
            },
            {
                name:"Section name",
                field:"section_name",
            },
            {
                name:"Point ID",
                field:"activated_point_point_id",
            },
            {
                name:"Point name",
                field:"point_name",
            },
            {
                name:"User",
                field:"user_name",
            },
            {
                name: "Date",
                field: "activated_point_date",
            }
        ],
        "fields":{
            "field000":{
                "name":"Section ID",
                "field":"section_id",
                "type":"TextInput",
                "list":true,
                "tab":2,
            },
            "field00":{
                "name":"Section name",
                "field":"section_name",
                "type":"TextInput",
                "list":true,
                "tab":2,
            },
            "field0":{
                "name":"Point id",
                "field":"activated_point_point_id",
                "type":"TextInput",
                "list":true,
                "tab":2,
            },
            "field1":{
                "name":"Point name",
                "field":"point_name",
                "type":"TextInput",
                "list":true,
                "tab":2,
            },
            "field2":{
                "name":"User",
                "field":"user_name",
                "type":"TextInput",
                "list":true,
                "tab":2,
            },
            "field3":{
                "name":"Visited",
                "field":"activated_point_date",
                "type":"TextInput",
                "list":true,
                "tab":2,
            }, 

        }
    }
}